<template>
    <div  data-animsition-in="fade-in" data-animsition-out="fade-out">

    <div class="row" style="width:100%;">
        <div class="col-lg-5 col-md-12 col-sm-12  col-12" style="background:#2c3e50">
            <div class="logo" style="padding: 30px 0px 0px 40px">
                <img height="50px" src="/img/bcart-white.png" alt="">
            </div>
            <div class="" >
                <div class="content" >
                    <h2 class="text-white">Login</h2>
                    <div class="login-caption text-white">Must have administrative rights</div>
                    <form action="">
                        <div class="form-group">
                            <label for="usr" class="text-white">Your e-mail:</label>
                            <input type="text" class="form-control" v-model="user.username" v-validate="'required'"  name="email" placeholder="supplier@example.com" id="usr">
                            <span style="color:red"><small>{{ errors.first('email') }}</small></span>
                        </div>
                        <div class="form-group">
                            <label for="pwd" class="text-white">Password:</label>
                            <input type="password" class="form-control" v-model="user.password" v-validate="'required'" name="password" placeholder="Password" id="pwd">
                            <span style="color:red"><small>{{ errors.first('password') }}</small></span>
                        </div>
                        
                        
                        <div style="text-align:center">
                            <button type="submit" 
                            @click.prevent="signIn()" v-if="!this.$store.state.routerStore.fetching" class="btn btn-info btn-block">Login</button>
                            <button type="submit" v-if="this.$store.state.routerStore.fetching" class="btn btn-info btn-block">
                                <i class="fa fa-spinner fa-spin"></i>
                            </button>
                        </div>
                        <br>
                        <div style="text-align:center">
                            <p>Don't have an account? Contact Super Admin </p>
                            <p style="color:white">Forgot password ?</p>
                        </div>
                    
                    </form>
                </div>
            </div>
        </div>
        <div  class="col-lg-7 col-md-12 col-sm-12  d-none d-lg-block">
            <div style="height: 100vh">
               <div class="container">
                   <div class="center">
                        <img src="/img/office_2x.jpg" alt="" >
                       
                   </div>   
               </div>

            </div>
            
           <!--  <div style="height:100vh; max-height:100vh; background: url('/img/office_2x.jpg');background-repeat: no-repeat;background-size:cover">
                <div style="font-size:20px;padding-top:2vh;padding-left:5vw;">
                    <div>
                        <router-link to="/" class="josefin" style="color:#1D4178">
                            Home
                        </router-link>
                        <router-link to="" class="josefin" style="padding-left: 40px; color:#1D4178">
                            Terms and Conditions
                        </router-link>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
       
    <!-- <section id="hero" class="">

        <div class="container">
        <div class="section-title">
            <h1><router-link to="/">Pipescore</router-link></h1>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-6 pt-2 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                <h2>Login</h2>
                <form action="">
                    <div class="input-group input-group-lg mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="background:white"><i class="fa fa-envelope"></i></span>
                        </div>
                        <input type="text" class="form-control" v-model="user.username" v-validate="'required'" id="inputEmail" name="username" placeholder="Username">
                       
                    </div>
                    <span style="color:red"><small>{{ errors.first('username') }}</small></span>
                    <div class="input-group input-group-lg mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="background:white"><i class="fa fa-lock"></i></span>
                        </div>
                        <input type="password" class="form-control" v-model="user.password" v-validate="'required'" id="inputPassword" name="password" placeholder="Password">
                        <br>
                        
                    </div>
                    <span style="color:red"><small>{{ errors.first('password') }}</small></span>
                   <div style="text-align:center">
                    <button type="submit" 
                    @click.prevent="signIn()" v-if="!this.$store.state.routerStore.fetching" class="btn-get-quote bg-white">Login</button>
                    <button type="submit" v-if="this.$store.state.routerStore.fetching" class="btn-get-quote bg-white">
                        <i class="fa fa-spinner fa-spin"></i>
                    </button>
                   </div>

                    
                </form>
                
                <div>You don't have an account yet? <router-link to="/auth/signup">Create one now!</router-link></div>
            
            </div>
            <div class="col-lg-6 order-1 order-lg-2 hero-img">
            <img src="/assets/img/social-media-management.jpg" class="" alt="">
            </div>
        </div>
        </div>

    </section> -->
    
    </div>
</template>
<script>
import AuthMixin from '../../mixins/authMixin.js'
    export default{
    data() {
        return {

        }
    },
    mixins: [AuthMixin],
     
    }
</script>

<style scoped>
body {
    background: transparent;
}
 .content {
   padding-top: 15vh;
   padding-left:10vw;
   padding-right:10vw;
    
   
}
 .content h2 {
     color: #1D4178;
     padding-bottom: 20px;
 }
 .login-caption {
     color: grey;
     font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
     padding-bottom: 20px;
 }
 .content label {
     color:#858B90;
 }
    .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    }
</style>