import AuthServices from '../services/Auth/auth'

export default {
    data() {
        return {
            user: {
                username: "",
                password: "",
                grant_type: "password",
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET ,
            // provider: 'api',
                scope: ""
            }
        }
    },
    created() {
        if(this.$store.state.authStore.authUser) {
            this.$router.go(-1)
        }
       

    },
    methods: {
        signIn() {
            this.$validator.validateAll()
                .then(() => {
                    if(!this.errors.any()) {
                        
                        this.authenticateUser()

                    }
                })
        },
        authenticateUser() {
                this.$store.dispatch('setFetching',true)
                AuthServices.signIn(this.user).then((response) => {
                    var authUser = {...response.token,user:response.user}
                    
                    var user = response.user
                    console.log(user)
                    if((user.role == "admin") && (user.status == "active")) {
                        this.$store.dispatch('setAuthUser',authUser)
                        this.$router.push({path: '/panel/dashboard'})
                    } else {
                        alert("Account is not an admin")
                    }
                    this.$store.dispatch('setFetching',false)

                }).catch((error) => {
                    this.$store.dispatch('setFetching',false)
                    this.$store.dispatch('error','Credentials are invalid')
                    console.log(error)
                })
        },

        
    },
    
   
}